<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <strong>{{ title }}</strong>
            <!-- <a-button class="btn btn-outline-secondary" v-on:click="refreshTable">
              Refresh
            </a-button> -->
            <!-- <a-button
              v-if="mode !== 'netral'"
              ref="undoBtn"
              class="btn btn-outline-secondary ml-2"
              :disabled="input.undoInputdisable"
              v-on:click="undo()"
            >
              {{ input.undoInput }} Undo
            </a-button>
            <a-button
              v-if="mode !== 'netral'"
              ref="redoBtn"
              class="ml-2 btn btn-outline-secondary"
              :disabled="input.redoInputdisable"
              v-on:click="redo()"
            >
              {{ input.redoInput }} Redo
            </a-button> -->
            <a-input
              v-model="tablequickfilter"
              class="float-right ml-2"
              placeholder="Find data..."
              style="width: 200px;"
            />
            <a-button
              v-if="mode === 'netral'"
              ref="addBtn"
              class="ml-2 btn btn-outline-primary"
              :disabled="mode !== 'netral' && mode !== 'oncreate'"
              @click="addNullData"
            >
              Tambah
            </a-button>
            <a-button
              v-if="mode === 'netral'"
              class="btn btn-outline-danger ml-2 mr-2"
              @click="removeOrDelete"
              :disabled="mode !== 'netral'"
            >
              Delete
            </a-button>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="saveChanges"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Save Changes
            </a-button>
            <a-button
              class="btn btn-outline-danger ml-2 mr-2"
              @click="cancelaction"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Cancel
            </a-button>
          </div>
        </div>
        <ag-grid-vue
          style="height: 50vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="allowPagination"
          :paginationPageSize="15"
          :cacheQuickFilter="true"
          :quickFilterText="tablequickfilter"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          @cell-value-changed="onCellValueChanged"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          :frameworkComponents="frameworkComponents"
          @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
          :rowClassRules="rowClassRules"
        >
        </ag-grid-vue>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <ag-grid-vue
          style="height: 23vh;"
          v-if="columnDefsTotal.length !== 0"
          :gridOptions="gridOptionsTotal"
          :rowClassRules="rowClassRules"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :columnDefs="columnDefsTotal"
          :rowData="rowDataTotal"
        ></ag-grid-vue>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import numericInput from '../component/numeric'
import selectInput from '../component/select'
import dateInput from '../component/date'
import tooltip from '../component/tooltip'
import htmlrender from '../component/htmlrender'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      title: 'Otorisasi Messages',
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      dataCabangList: [],
      tablequickfilter: '',
      gridOptions: null,
      // gridOptionsTotal: null,
      allowPagination: localStorage.getItem('gridpagination') || false,
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      columnDefs: [],
      columnDefsTotal: [],
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      rowData: null,
      rowDataTotal: null,
      changedData: [],
      columnCurrency: [],
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },
      rowAfterFilter: [],
      componentKey: 0,
      whatform: 'messages',
    }
  },
  created() {
    this.getAllMaster()
    // console.log('this.allMaster', this.allMaster)
    this.gridOptions = {}
    // this.gridOptionsTotal = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.kodeCabang = localStorage.getItem('defaultsandikc') || 'all'
    this.dataCabangList = this.user.cabangList
    // console.log('this.kodeCabang', this.kodeCabang)
    // this.getColumnDefs()
    // this.getAllData()
  },
  methods: {
    moment,
    // handleMenuClick(e) {
    //   console.log('click', e)
    // },
    paginationruleschange() {
      this.allowPagination = !this.allowPagination
      localStorage.setItem('gridpagination', this.allowPagination)
      // location.reload()
      this.componentKey += 1
    },
    // previousPeriod() {
    //   var periode = this.$store.state.user.periode
    //   console.log('before', periode)
    //   var after = moment(periode, 'YYYY-MM-DD').subtract(1, 'months').endOf('months').format('YYYY-MM-DD')
    //   console.log('after', after)
    // },
    showModal() {
      this.modal.visible = true
    },
    hitungTotal() {
      var dataTotal = []
      var groupbysandi = this.rowAfterFilter.reduce(function (r, a) {
        if (a.sandikantor !== undefined && a.sandikantor !== null) {
          r[a.sandikantor] = r[a.sandikantor] || []
          r[a.sandikantor].push(a)
        }
        return r
      }, Object.create(null))
      var tempsandikantor = Object.keys(groupbysandi)
      var sandikantor = tempsandikantor.length !== 0 ? tempsandikantor.sort() : []
      // console.log('groupbysandi', groupbysandi)
      // console.log('sandikantor', sandikantor)
      var columncurrency = this.columnCurrency
      for (let index = 0; index < sandikantor.length; index++) {
        const keyobject = sandikantor[index]
        // console.log('columncurrency', columncurrency)
        // console.log('keyobject', keyobject)
        var datap = {
          // from: keyobject,
        }
        for (let i = 0; i < columncurrency.length; i++) {
          if (i === 0) datap.from = keyobject
          const el = columncurrency[i]
          // eslint-disable-next-line no-redeclare
          var total = 0
          for (let u = 0; u < groupbysandi[keyobject].length; u++) {
            const element = groupbysandi[keyobject][u]
            total += element[el.field]
          }
          datap[el.field] = total
          datap.jumlahdata = groupbysandi[keyobject].length
        }
        // console.log('datap', datap)
        if (Object.keys(datap).length !== 0) {
          dataTotal.push(datap)
        }
      }
      var dataptotal = {}
      for (let i = 0; i < columncurrency.length; i++) {
        if (i === 0) dataptotal.from = 'Total'
        const el = columncurrency[i]
        // eslint-disable-next-line no-redeclare
        var total = 0
        for (let u = 0; u < this.rowAfterFilter.length; u++) {
          const element = this.rowAfterFilter[u]
          total += element[el.field]
        }
        dataptotal[el.field] = total
        dataptotal.jumlahdata = this.rowAfterFilter.length
      }
      dataptotal.isPrimary = 1
      dataptotal.isBold = true
      // dataptotal.jumlahdata =
      // console.log('dataptotal', dataptotal)
      if (Object.keys(dataptotal).length !== 0) {
        dataTotal.push(dataptotal)
      }
      // console.log('this.columnCurrency', this.columnCurrency)
      this.columnDefsTotal = this.$g.clone(this.columnCurrency).map((cv, index) => {
        var ret = cv
        cv.valueFormatter = this.currencyFormatter
        cv.type = 'rightAligned'
        // console.log('ret', ret)
        return ret
      })
      if (this.columnCurrency.length !== 0) {
        this.columnDefsTotal.splice(0, 0, {
          headerName: 'From',
          field: 'from',
          width: '70',
          sortable: true,
        })
      }
      this.columnDefsTotal.push({
        headerName: 'Jumlah data',
        field: 'jumlahdata',
        width: '70',
        sortable: true,
      })
      // console.log('this.columnDefsTotal', this.columnDefsTotal)
      this.rowDataTotal = this.$g.clone(dataTotal)
      // console.log('dataTotal', dataTotal)
      // console.log('this.rowDataTotal', this.rowDataTotal)
      // console.log('dataTotal', dataTotal)
    },
    filterKodeCabang() {
      if (this.kodeCabang === 'all') {
        this.rowAfterFilter = this.$g.clone(this.backupRowData)
      } else {
        var newdata = this.backupRowData.filter(
          (x) => x.sandikantor === this.kodeCabang,
        )
        this.rowAfterFilter = this.$g.clone(newdata)
      }
      this.rowData = this.rowAfterFilter
      // this.hitungTotal()
    },
    async getAllMaster() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(x => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id)
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet('master', false, true)
        var master = {
          mykey: 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(x => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id)
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      allmaster.cabanglist = cabanglist
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      this.getColumnDefs()
      this.getAllData()
    },
    async getColumnDefs() {
      var nindexeddb = await lou.getDataFromDb()
      // console.log('nindexxeddb', nindexeddb)
      var gcIndexTarget = nindexeddb.findIndex(x => x.mykey === 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var column = []
      var res = null
      // console.log('res.data', res.data)
      if (gcIndexTarget < 0) {
        res = await lou.customUrlGet('master/tables', false, true)
        // console.log('res.data', res.data)
        column = res.data[this.whatform]
        var master = {
          mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        // console.log('column', column)
      } else {
        var dataFDB = JSON.parse(nindexeddb[gcIndexTarget].data)
        // console.log('dataFDB', dataFDB)
        if (dataFDB[this.whatform] !== undefined) {
          column = dataFDB[this.whatform]
        } else {
          await lou.deleteDataFromDb(nindexeddb[gcIndexTarget].id)
          res = await lou.customUrlGet('master/tables', false, true)
          column = res.data[this.whatform]
          // console.log('column', column)
          var masters = {
            mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
            data: JSON.stringify(res.data),
          }
          await lou.addDataToDb(masters)
        }
        // console.log('column', column)
      }
      this.columnDefs = []
      this.contohdata = {}
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var data = element
        if (data.isHide === 'false') {
          if (data.valueFormatter === '') {
            delete data.valueFormatter
          } else {
            if (data.valueFormatter === 'currency') {
              this.columnCurrency.push({
                field: data.field.toLowerCase(),
                headerName: data.headerName,
                valueFormatter: this.currencyFormatter,
                resizable: true,
              })
              data.valueFormatter = this.currencyFormatter
            }
          }
          if (data.reftable !== undefined && data.reftable !== '' && data.reftable !== null) {
            var object = this.allMaster[data.reftable]
            if (object !== undefined) {
              data.cellEditor = 'selectEditor'
              data.cellEditorParams = {
                values: object,
                reffield: data.retreffield,
              }
            }
          }
          if (data.cellEditor === '') delete data.cellEditor
          if (data.pinned === '') delete data.pinned
          data.editable = data.editable === 'true'
          if (data.filter === 'true') {
            data.filter = true
          } else if (data.filter === 'false') {
            data.filter = false
          } else if (data.filter === 'agDateColumnFilter') {
            data.filterParams = {
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                var dateAsString = cellValue
                if (dateAsString == null) return -1
                var dateParts = dateAsString.split('-')
                var cellDate = new Date(
                  Number(dateParts[0]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[2]),
                )
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0
                }
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1
                }
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1
                }
              },
            }
          }
          data.sortable = data.sortable === 'true'
          data.resizable = data.resizable === 'true'
          data.field = data.field.toLowerCase()
          // data.cellClassRules = data.rules
          data.tooltipField = data.field
          data.tooltipComponent = 'customTooltip'
          this.contohdata[data.field] = ''
          this.columnDefs.push(data)
        }
      }
      this.refreshTable()
    },
    async getAllData() {
      var res = await lou.readMaster(this.whatform, false, false)
      // console.log('res', res.data)
      this.rowData = res.data
      this.backupRowData = this.$g.clone(this.rowData)
      this.rowAfterFilter = this.$g.clone(this.rowData)
      // this.hitungTotal()
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // this.componentKey += 1
      }
      // console.log('this.changedData', this.changedData)
      // console.log('this.mode', this.mode)
    },
    checkData() {
      // this.
    },
    async saveChanges() {
      var fd = this.changedData.length !== 0 ? this.$g.clone(this.changedData) : []
      // var user = this.$store.state.user
      if (fd.length === 0) lou.shownotif('Belum ada perubahan')
      if (this.mode === 'oncreate') {
        fd.forEach((element) => {
          delete element.id
          if (element.newdata) {
            delete element.newdata
          }
        })
      } else if (this.mode === 'onupdate') {
        fd.forEach((element) => {
          element.id = parseInt(element.id)
        })
      }
      // fd[0].tglmulai = fd[0].tglmulai.slice(0, 10)
      // fd[0].tgljatuhtempo = fd[0].tgljatuhtempo.slice(0, 10)
      // fd[0].tglpenilaianterakhir = fd[0].tglpenilaianterakhir.slice(0, 10)
      var res = {}
      var datasend = fd[0]
      if (this.mode === 'oncreate') {
        // console.log('fd', fd)
        res = await lou.createMaster(this.whatform, datasend, true, false)
      } else {
        res = await lou.updateMaster(this.whatform, datasend, true, false)
        // console.log('fd', fd)
      }
      if (res) {
        this.mode = 'netral'
        this.changedData = []
        this.getAllData()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows[0].karat)
      // console.log('this.columnCurrency', this.columnCurrency)
    },
    addNullData() {
      this.mode = 'oncreate'
      var fd = {}
      this.columnDefs.forEach((element, index) => {
        var booltgl = element.cellEditor === 'dateEditor'
        if (booltgl) {
          fd[element.field] = null
        } else if (element.cellEditor === 'numericEditor') {
          fd[element.field] = 0
        } else {
          fd[element.field] = null
        }
      })
      fd.id = this.$uuid.v4()
      fd.newdata = true
      this.rowData.splice(0, 0, fd)
    },
    removeOrDelete() {
      // console.log('this.selectedData', this.selectedData)
      if (this.selectedData === null) {
        lou.shownotif(
          'Anda belum memilih data atau data yang anda pilih harus tidak boleh lebih dari 1',
        )
      } else {
        if (this.selectedData[0].newdata) {
          var indexTarget = this.rowData.findIndex(
            (x) => x.id === this.selectedData[0].id,
          )
          this.rowData.splice(indexTarget, 1)
        } else {
          this.confirmationDelete(this.selectedData[0].id)
          // send to api
        }
      }
    },
    cancelaction() {
      // console.log('this.mode', this.mode)
      if (this.mode === 'oncreate') {
        var maped = this.rowData.filter((x) => x.newdata === undefined)
        // console.log('maped', maped)
        this.rowData = maped
      } else {
        // var changedRowNode = []
        this.rowData = this.$g.clone(this.backupRowData)
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
      this.refreshTable()
      // for (let i = 0; i < this.input.undoInput; i++) {
      //   this.gridApi.undoCellEditing()
      // }
    },
    async confirmationDelete(id) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.deleteMaster(this.whatform, id, true, false)
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    refreshTable() {
      var params = {
        force: true,
        suppressFlash: false,
      }

      // this.gridOptions.api.refreshHeader()
      this.gridOptions.api.refreshCells(params)
      // console.log('kerefresh kok')
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    undo() {
      this.gridApi.undoCellEditing()
    },
    redo() {
      this.gridApi.redoCellEditing()
    },
    currencyFormatter(params) {
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    titleFormat(word) {
      return lou.capitalizeTheFirstLetterOfEachWord(word)
    },
  },
}
</script>

<style></style>
